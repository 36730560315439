<template>
  <v-row>
    <v-col cols="12" sm="3">
      <c-a-stats-card
        title="CA à date"
        subtitle="vs objectif"
        :ca="ca"
        :objectif="objectif"
        :objectif-percent="objectifPercent"
      ></c-a-stats-card>
    </v-col>
    <v-col cols="12" sm="3">
      <c-a-stats-card
        title="CA M-1"
        subtitle="vs objectif"
        :ca="caM1"
        :objectif="objectifM1"
        :objectif-percent="objectifPercentM1"
      ></c-a-stats-card>
    </v-col>
    <v-col cols="12" sm="3">
      <v-card class="mb-6">
        <v-card-title>
          <div>
            <p class="mb-0 font-weight-bold text-xl">Estimation en €</p>
            <small class="text--secondary text-xs text-no-wrap">{{
              estimationEur.statistics
            }}</small>
          </div>
        </v-card-title>
      </v-card>
      <v-card>
        <v-card-title>
          <div>
            <p class="mb-0 font-weight-bold text-xl">Estimation en %</p>
            <small class="text--secondary text-xs text-no-wrap">{{
              estimationPercent.statistics
            }}</small>
          </div>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" sm="3">
      <v-card class="mb-6">
        <v-card-title>
          <div>
            <p class="mb-0 font-weight-bold text-xl">Sessions</p>
            <small class="text--secondary text-xs text-no-wrap">{{
              sessions
            }}</small>
          </div>
        </v-card-title>
      </v-card>
      <v-card>
        <v-card-title>
          <div>
            <p class="mb-0 font-weight-bold text-xl">Objectif Sessions</p>
            <small class="text--secondary text-xs text-no-wrap">{{
              sessionsObjective
            }}</small>
          </div>
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { cacheTime } from "@axios";

import {
  formatCurrency,
  formatCurrencyCompactLong,
  formatNumberCompact,
  formatPercent,
} from "@/utils/formatting";
import CAStatsCard from "@/components/premium/general/cards/CAStatsCard";

import useAxios from "@/hooks/useAxios";

export default {
  name: "TopCards",
  components: {
    CAStatsCard,
  },
  data() {
    return {
      loading: false,
      ca: {
        statTitle: "CA à date",
        statistics: "0",
        statisticsRaw: "0",
      },
      caM1: {
        statTitle: "CA à M-1",
        statistics: "0",
        statisticsRaw: "0",
      },
      objectif: {
        statTitle: "Objectif",
        statistics: "0",
        statisticsRaw: "0",
      },
      objectifM1: {
        statTitle: "Objectif M-1",
        statistics: "0",
        statisticsRaw: "0",
      },
      objectifPercent: {
        statTitle: "Pourcentage de l'objectif à date",
        statistics: "0",
        statisticsRaw: "0",
      },
      objectifPercentM1: {
        statTitle: "Pourcentage de l'objectif à M-1",
        statistics: "0",
        statisticsRaw: "0",
      },
      estimationEur: {
        statTitle: "Estimation en €",
        statistics: "0",
        statisticsRaw: "0",
      },
      estimationPercent: {
        statTitle: "Total Estimation en %",
        statistics: "0",
        statisticsRaw: "0",
      },
      sessions: 0,
      sessionsObjective: 0,
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        const queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };

        this.loading = true;
        const { data } = await this.axiosGet(
          "/programmatic/total-monthly-report",
          queryParams,
          {
            cache: {
              maxAge: cacheTime,
            },
          }
        );

        this.estimationEur.statistics = formatCurrencyCompactLong(
          data["ca_estimate_eur"]
        );
        this.estimationEur.statisticsRaw = formatCurrency(
          data["ca_estimate_eur"]
        );
        this.ca.statistics = formatCurrencyCompactLong(data["current_ca"]);
        this.ca.statisticsRaw = formatCurrency(data["current_ca"]);
        this.caM1.statistics = formatCurrencyCompactLong(data["previous_ca"]);
        this.caM1.statisticsRaw = formatCurrency(data["previous_ca"]);
        this.objectif.statistics = formatCurrencyCompactLong(
          data["current_ca_objective"]
        );
        this.objectif.statisticsRaw = formatCurrency(
          data["current_ca_objective"]
        );
        this.objectifM1.statistics = formatCurrencyCompactLong(
          data["previous_ca_objective"]
        );
        this.objectifM1.statisticsRaw = formatCurrency(
          data["previous_ca_objective"]
        );
        this.objectifPercent.statistics = formatPercent(
          data["current_percent_objective"]
        );
        this.objectifPercent.statisticsRaw = this.objectifPercent.statistics;
        this.objectifPercentM1.statistics = formatPercent(
          data["previous_percent_objective"]
        );
        this.objectifPercentM1.statisticsRaw =
          this.objectifPercentM1.statistics;
        this.estimationPercent.statistics = formatPercent(
          data["ca_estimate_perct"]
        );
        this.estimationPercent.statisticsRaw =
          this.estimationPercent.statistics;
        this.sessions = formatNumberCompact(data["current_sessions"]);
        this.sessionsObjective = formatNumberCompact(
          data["current_sessions_objective"]
        );

        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    month() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
