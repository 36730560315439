<template>
  <line-chart
    title="CA vs Objectif"
    :loading="loading"
    :colors="colors"
    :series="series"
    :yAxis="yAxis"
    :xAxis="xAxis"
  ></line-chart>
</template>

<script>
import { cacheTime } from "@axios";

import useAxios from "@/hooks/useAxios";
import { runParallelAsyncs } from "@/utils/async";
import { colors } from "@/utils/constants";
import { getDaysArray, monthRange } from "@/utils/dates";
import { formatNumber } from "@/utils/formatting";
import LineChart from "@/components/common/charts/LineChart";

const yAxis = (color = "") => ({
  min: 0,
  forceNiceScale: true,
  labels: {
    formatter: (val) => formatNumber(val),
    style: {
      fontSize: "12px",
      fontWeight: 400,
      ...(color ? { colors: color } : {}),
    },
  },
});

export default {
  name: "CaObjectivesChart",
  components: {
    LineChart,
  },
  data() {
    return {
      loading: false,
      series: [
        {
          name: "CA",
          data: [],
        },
        {
          name: "Objectif",
          data: [],
        },
      ],
      colors: [colors.ca, colors.objective], // "#2da0ed", "#00d4bd", "#9C27B0",
      yAxis: [
        // Order must be the same than in series definitions
        {
          seriesName: "CA",
          title: {
            text: "CA",
            style: { color: colors.ca },
          },
          ...yAxis(colors.ca),
        },
        {
          opposite: true,
          seriesName: "Objectif",
          title: {
            text: "Objectif",
            style: { color: colors.objective },
          },
          ...yAxis(colors.objective),
        },
      ],
      xAxis: {
        categories: [],
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        const queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };
        const range = monthRange(this.month);
        const categories = getDaysArray(range[0], range[1]);
        const mainData = {};
        for (const x of categories) {
          mainData[x] = {
            ca: null,
            objRevenue: null,
          };
        }

        this.loading = true;
        const {
          detailedRevenueResponse: { data: detailedRevenueData },
          detailedAudienceResponse: { data: detailedAudienceData },
          totalResponse: { data: totalData },
        } = await runParallelAsyncs({
          detailedRevenueResponse: this.axiosGet(
            "/programmatic/detailed-revenue-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
          detailedAudienceResponse: this.axiosGet(
            "/programmatic/detailed-audience-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
          totalResponse: this.axiosGet(
            "/programmatic/total-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
        });

        delete queryParams["partner_views"];
        queryParams["objtype"] = "sessions";
        const { data: objectivesSessionsData } = await this.axiosGet(
          "/objectives/",
          queryParams,
          {
            cache: {
              maxAge: cacheTime,
            },
          }
        );

        this.xAxis = {
          ...this.xAxis,
          categories: categories,
        };

        detailedRevenueData.map((data) => {
          mainData[data["date"]]["ca"] = data["ca"];
        });
        detailedAudienceData.map((data) => {
          mainData[data["date"]]["sessions"] = data["sessions"];
        });
        const objectiveRevenueDay =
          totalData["current_ca_objective"] / categories.length;

        const objectiveSessionsMonth = objectivesSessionsData["value"];
        const objectiveSessionsDay = objectiveSessionsMonth / categories.length;

        const objectiveRPMDay =
          (objectiveRevenueDay / objectiveSessionsDay) * 1000;
        for (const x of categories) {
          mainData[x]["objRevenue"] = objectiveRevenueDay;
          mainData[x]["objSessions"] = objectiveSessionsDay;
          mainData[x]["objRPM"] = objectiveRPMDay;
        }

        this.series = [
          {
            name: "CA",
            data: categories.map((category) => mainData[category]["ca"]),
          },
          {
            name: "Objectif",
            data: categories.map(
              (category) => mainData[category]["objRevenue"]
            ),
          },
        ];

        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    month() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
