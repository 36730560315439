<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="6" sm="4" md="3">
        <partner-group-filter store="premium" />
      </v-col>
      <v-col cols="6" sm="4" md="6">
        <v-row>
          <v-col cols="8">
            <partner-view-filter store="premium" />
          </v-col>
          <v-col cols="4">
            <group-ca-filter store="premium" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" sm="4" md="3">
        <date-filter
          store="premium"
          storeGetter="getMonthDate"
          storeUpdater="updateMonthDate"
          format="month"
          :isRange="false"
        />
      </v-col>
    </v-row>
    <v-row class="d-flex">
      <v-col cols="6" sm="4" md="3">
        <site-group-filter store="premium" />
      </v-col>
      <v-col cols="6" sm="4" md="6">
        <site-filter store="premium" />
      </v-col>

      <v-col cols="3">
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters";
import DateFilter from "@/components/common/filters/DateFilter";
import GroupCaFilter from "@/components/common/filters/GroupCaFilter.vue";
import PartnerGroupFilter from "@/components/common/filters/PartnerViewGroupFilter";
import PartnerViewFilter from "@/components/common/filters/PartnerViewFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";

import useAxios from "@/hooks/useAxios";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
    GroupCaFilter,
    PartnerGroupFilter,
    PartnerViewFilter,
    SiteFilter,
    SiteGroupFilter,
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {},
};
</script>

<style>
.v-date-picker-title__date > div {
  font-size: 22px;
}
</style>
